











import { Component, Vue } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'BaseAddEntityBlock',
  components: { BaseButton, BaseIcon },
})
export default class BaseAddEntityBlock extends Vue {}
