





































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')
const LocationsStore = namespace('LocationsStore')
const StatististicsStore = namespace('StatististicsStore')
import { UserModel } from '@/core/models/UserModel'
import { LocationModel } from '@/core/models/LocationModel'
import { LocationShortModel } from './models/LocationShortModel'

import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import StatisticsCharts from '@/modules/components/StatisticsCharts.vue'
import Location from './components/Location.vue'
import AddressForm from '@/modules/components/AddressForm.vue'
import Modal from '@/components/Modal.vue'
import BaseAddEntityBlock from '@/components/base/BaseAddEntityBlock.vue'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'
import TopBanner from "@/components/banners/TopBanner.vue";

@Component({
  name: 'MyLocations',
  components: {
    BaseAddEntityBlock,
    Content,
    BaseTitle,
    BaseButton,
    BaseIcon,
    Location,
    StatisticsCharts,
    Modal,
    AddressForm,
    TippyComponent,
    TopBanner
  },
  metaInfo: {
    title: 'Список помещений',
  },
})
export default class MyLocationsPage extends Vue {
  showAddressForm = false
  showLocationModal = false

  @UserStore.Getter
  public currentUser!: UserModel

  @StatististicsStore.Getter
  public statististicsList!: any

  @LocationsStore.Getter
  public locationList!: LocationShortModel[]

  @LocationsStore.Action
  private getLocations!: () => Promise<LocationShortModel[]>

  @LocationsStore.Action
  private getLocation!: (id: number) => Promise<LocationModel>

  @StatististicsStore.Action
  private getStatististics!: () => Promise<any>

  @StatististicsStore.Action
  private getBannerStatististics!: (id: any) => Promise<void>

  get addAvailable(): boolean {
    if (
      !this.currentUser?.billing_plan?.current &&
      !this.currentUser?.billing_plan?.next
    ) {
      return false
    }

    const maxLocations = this.currentUser?.billing_plan?.current
      ? this.currentUser?.billing_plan?.current?.max_locations
      : this.currentUser?.billing_plan?.next?.max_locations
    return (
      (maxLocations !== undefined && maxLocations == 0) ||
      (maxLocations !== undefined &&
        maxLocations > 0 &&
        this.locationList?.length < maxLocations)
    )
  }

  @Watch('currentUser')
  onCurrentUserChanged() {
    this.getBannerStatististics(this.currentUser.requisites.id)
  }

  created(): void {
    this.getLocations()
    this.getStatististics()

  }

  addLocation(): void {
    this.showAddressForm = true
      ; (this.$refs['addressForm'] as any)?.resetModel?.()
  }

  async editLocation(id: number): Promise<void> {
    this.showAddressForm = true
    const locationToEdit = await this.getLocation(id)
      ; (this.$refs['addressForm'] as any)?.setModel?.(locationToEdit)
  }
}
