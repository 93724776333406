





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { LocationShortModel } from '../models/LocationShortModel'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'Location',
  components: { BaseButton, BaseIcon },
})
export default class Room extends Vue {
  @Prop({ required: true })
  location!: LocationShortModel
}
